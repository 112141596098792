import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  HostListener,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';

import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import { SignUpService } from '../sign-up-form.service';
import { CustomContentValue } from '../../custom-content';
import { CurrentStepService } from '../../currentstep.service';

const SIGNUP_TYPES = {
  google: 'google',
  microsoft: 'microsoft',
  linkedin: 'linkedin',
};

// eslint-disable-next-line no-useless-escape
const HACKERONE_EMAIL_REGEX = /[a-zA-Z0-9_.+\-]+@wearehackerone.com/;

@Component({
  selector: 'app-sign-up-step-one',
  styleUrls: ['./sign-up-step-one.component.scss'],
  templateUrl: './sign-up-step-one.component.html',
})
export class SignUpStepOneComponent implements OnInit, AfterViewInit {
  @Input() loginURL: string;
  @Input() nextUrl: string;
  @Input() customContent$$: BehaviorSubject<CustomContentValue>;
  @Input() form: UntypedFormGroup;
  @Input() recaptchaV2isEmailInUse: boolean;
  @Input() captchaRef: RecaptchaComponent;
  @Input() recaptchaSiteKey: string;
  @Input() formFieldEnabled: { [key: string]: BehaviorSubject<boolean> };

  signUpVariant$$ = new BehaviorSubject<'DEFAULT' | 'HACKERONE'>('DEFAULT');
  googleOptionSelected$$ = new BehaviorSubject<boolean>(false);
  microsoftOptionSelected$$ = new BehaviorSubject<boolean>(false);
  linkedOptionSelected$$ = new BehaviorSubject<boolean>(false);
  emailFormStatus$$ = new BehaviorSubject('VALID');

  @ViewChildren('emailInput') emailInput: QueryList<ElementRef<HTMLInputElement>>;

  constructor(
    private signUpService: SignUpService,
    private snackbarService: SnackbarService,
    private currentStepService: CurrentStepService,
  ) {}

  ngOnInit(): void {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get('variant') && queryParams.get('variant').toLowerCase() === 'hackerone') {
      this.setHackerOneVariantEmailValidation();
      this.setHackerOneVariantFormContent();
      this.signUpVariant$$.next('HACKERONE');
    }
  }

  ngAfterViewInit(): void {
    this.emailInput.first.nativeElement.focus();
  }

  @HostListener('window:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    const keyEnter = 13;
    if (event.keyCode === keyEnter) {
      this.onSubmitStepOne();
    }
  }

  private setHackerOneVariantEmailValidation(): void {
    this.form.get('email').addValidators((control) => {
      if (control.value && !HACKERONE_EMAIL_REGEX.test(control.value)) {
        return { nonHackerOneEmail: true };
      }
      return null;
    });
    this.form.updateValueAndValidity();
  }

  private setHackerOneVariantFormContent(): void {
    const content = this.customContent$$.value;
    this.customContent$$.next({
      ...content,
      formTitle: 'SIGNUP.SETUP.STEP_ONE_HACKERONE.TITLE',
      formSubtitle: 'SIGNUP.SETUP.STEP_ONE_HACKERONE.SUBTITLE',
    });
  }

  private createInitialSSOStateParams(): URLSearchParams {
    const currentParams = new URLSearchParams(window.location.search);
    if (this.nextUrl) {
      currentParams.set('nextUrl', this.nextUrl);
    }
    return currentParams;
  }

  googleSignUpSelection(): void {
    const currentParams = this.createInitialSSOStateParams();
    currentParams.append('signuptype', SIGNUP_TYPES.google);
    this.googleOptionSelected$$.next(true);
    this.signUpService.redirectToGoogleAuth(btoa(currentParams.toString()));
  }

  microsoftSignUpSelection(): void {
    const currentParams = this.createInitialSSOStateParams();
    currentParams.append('signuptype', SIGNUP_TYPES.microsoft);
    this.microsoftOptionSelected$$.next(true);
    this.signUpService.redirectToMicrosoftAuth(btoa(currentParams.toString()));
  }

  linkedinSignUpSelection(): void {
    const currentParams = this.createInitialSSOStateParams();
    currentParams.append('signuptype', SIGNUP_TYPES.linkedin);
    this.linkedOptionSelected$$.next(true);
    this.signUpService.redirectToLinkedinAuth(btoa(currentParams.toString()));
  }

  resolvedRecaptchaV2isEmailInUse(captchaToken: string): void {
    const email = this.form.get('email')?.value;
    this.signUpService.isEmailInUse(email, captchaToken, false).subscribe({
      next: (res) => {
        if (!res?.emailInUse) {
          this.recaptchaV2isEmailInUse = false;
          return;
        }
        if (res?.emailInUse && res?.persona === 'partner') {
          this.form.controls['email'].setErrors({ emailInUsePartner: true });
          return;
        }
        if ((res?.emailInUse && res?.persona === 'salesperson') || res?.persona === 'digital-agent') {
          this.form.controls['email'].setErrors({ emailInUse: true });
          return;
        }
      },
      error: () => {
        this.captchaRef?.reset();
        return of(this.snackbarService.openErrorSnack('COMMON.RECAPTCHA_ERROR_TRY_AGAIN'));
      },
    });
  }

  onSubmitStepOne(): void {
    const emailControl = this.form.get('email');

    if (this.emailFormStatus$$.value === 'PENDING') {
      console.warn(`Email validation in progress, can't proceed to next step`);
      return;
    }

    if (emailControl.dirty && !emailControl.errors && !this.recaptchaV2isEmailInUse) {
      const displayedSteps = new Map();
      for (const [key, subject] of Object.entries(this.formFieldEnabled)) {
        displayedSteps.set(key, subject.value);
      }

      this.currentStepService.incrementStep(Object.fromEntries(displayedSteps));
    } else {
      emailControl.markAsDirty();
      emailControl.markAsTouched();
    }
  }
}
