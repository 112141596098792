<div class="sign-up-form-container">
  <div class="sign-up-form">
    <div class="sign-up-form-swiper-container">
      <div #formContainer class="sign-up-form-swiper" [formGroup]="form">
        <div
          class="sign-up-form-swiper-item small-container"
          *ngIf="showFirstStep"
          [class.show-step]="(currentStep$ | async) === SIGNUP_PROVIDER_STEP"
        >
          <app-sign-up-step-one
            [loginURL]="loginURL"
            [nextUrl]="nextUrl"
            [customContent$$]="customContent$$"
            [form]="form"
            [recaptchaV2isEmailInUse]="recaptchaV2isEmailInUse"
            [captchaRef]="captchaRef"
            [recaptchaSiteKey]="recaptchaSiteKey"
            [formFieldEnabled]="formFieldEnabled"
          ></app-sign-up-step-one>
        </div>

        <div
          class="sign-up-form-swiper-item small-container"
          [class.show-step]="(currentStep$ | async) === ACCOUNT_INFO_STEP"
        >
          <ng-template [ngTemplateOutlet]="signUpStepTwo"></ng-template>
        </div>
        <div
          class="sign-up-form-swiper-item small-container"
          [class.show-step]="(currentStep$ | async) === SIGNUP_LOADING_SCREEN"
        >
          <ng-template [ngTemplateOutlet]="afterSignUpWaiting"></ng-template>
        </div>
        <div
          class="sign-up-form-swiper-item small-container"
          [class.show-step]="(currentStep$ | async) === VERIFY_EMAIL_STEP"
        >
          <ng-template [ngTemplateOutlet]="verifyEmailStep"></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<re-captcha
  *ngIf="renderRecaptchaV2"
  #captchaRef="reCaptcha"
  size="invisible"
  (resolved)="$event && resolvedRecaptchaV2($event)"
  [siteKey]="recaptchaSiteKey"
></re-captcha>

<!-- NOTE: These templates could be moved into seperate components to simplify logic somewhat -->
<ng-template #signUpStepTwo>
  <div class="sign-up-form-explanation sign-up-form-swiper-container small-container">
    <ng-container>
      <div class="sign-up-form-explanation-title" data-testid="account-info-title">
        {{ 'SIGNUP.SETUP.STEP_TWO_TRIAL.TRIAL_TITLE' | translate }}
      </div>
      <div class="sign-up-form-explanation-subtitle">
        {{ 'SIGNUP.SETUP.STEP_TWO_TRIAL.SUBTITLE' | translate }}
      </div>
    </ng-container>
  </div>
  <div>
    <div class="form-container" #stepTwoFormContainer data-testid="stepTwo-form">
      <div>
        <div
          class="two-form-fields-block"
          *ngIf="(formFieldEnabled.firstName | async) || (formFieldEnabled.lastName | async)"
        >
          <glxy-input
            *ngIf="formFieldEnabled.firstName | async"
            [placeholder]="'SIGNUP.FIRST_NAME'"
            [label]="'SIGNUP.FIRST_NAME'"
            required="true"
            [formControl]="form.get('firstName')"
            data-testid="firstName-input"
            id="firstName"
          ></glxy-input>

          <glxy-input
            *ngIf="formFieldEnabled.lastName | async"
            [placeholder]="'SIGNUP.LAST_NAME'"
            [label]="'SIGNUP.LAST_NAME'"
            required="true"
            [formControl]="form.get('lastName')"
            data-testid="lastName-input"
            id="lastName"
          ></glxy-input>
        </div>

        <glxy-password-input
          *ngIf="formFieldEnabled.password | async"
          [placeholder]="'SIGNUP.PASSWORD'"
          [label]="'SIGNUP.PASSWORD'"
          [validators]="passwordValidators"
          required="true"
          [formControl]="form.get('password')"
          data-testid="password-input-component"
          id="password"
        ></glxy-password-input>

        <google-map
          style="display: none"
          [options]="{ fullscreenControl: false, mapTypeControl: false }"
          (mapInitialized)="onMapReady($event)"
        ></google-map>
        <EXP__glxy-wrap *ngIf="formFieldEnabled.companyName | async">
          <mat-form-field>
            <mat-label>
              {{ 'SIGNUP.COMPANY_NAME' | translate }}
            </mat-label>
            <input
              id="companyName"
              name="companyName"
              required
              matInput
              placeholder="{{ 'SIGNUP.COMPANY_NAME' | translate }}"
              [formControl]="form.get('companyName')"
              [matAutocomplete]="auto"
              (keyup)="companyOnFieldChange($event)"
              data-testid="companyName-input"
            />
            <mat-error *ngIf="form.get('companyName')?.hasError('required')">
              {{ 'COMMON.FIELD_REQUIRED' | translate: { fieldName: 'SIGNUP.COMPANY_NAME' | translate } }}
            </mat-error>
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="companyDisplayValue"
              (optionSelected)="companyOnSelected($event.option.value)"
              class="company-options"
            >
              <mat-option
                [ngClass]="{
                  'autocomplete-suggestion': true,
                  'suggestion-not-found': isLast,
                }"
                *ngFor="let location of locations; last as isLast"
                [value]="location"
              >
                <span class="main-text">{{ location.mainText }}</span>
                <br />
                <span class="secondary-text">{{ location.secondaryText }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </EXP__glxy-wrap>
        <glxy-select-input
          *ngIf="formFieldEnabled.numberOfEmployees | async"
          required="true"
          [label]="'SIGNUP.NUMBER_OF_EMPLOYEES'"
          [formControl]="this.form.get('numberOfEmployees')"
          [options]="numberOfEmployees"
          data-testid="numberOfEmployees-input"
        ></glxy-select-input>

        <ng-container *ngIf="formFieldEnabled.countrySearchControl | async">
          <EXP__glxy-wrap *ngIf="countryOptions$ | async as countryOptions; else countriesLoading">
            <mat-form-field *ngIf="countryLabelHack$ | async" data-testid="country-field">
              <mat-label>{{ 'SIGNUP.COUNTRY' | translate }}</mat-label>
              <input
                type="text"
                attr.aria-label="{{ 'SIGNUP.COUNTRY' | translate }}"
                required
                matInput
                [formControl]="form.get('countrySearchControl')"
                [matAutocomplete]="countryAuto"
                data-testid="country-input"
                name="country"
              />
              <mat-error *ngIf="form.get('countrySearchControl')?.invalid">
                <ng-container *ngIf="form.get('countrySearchControl')?.hasError('required')">
                  {{ 'COMMON.FIELD_REQUIRED' | translate: { fieldName: 'SIGNUP.COUNTRY' | translate } }}
                </ng-container>
                <ng-container *ngIf="form.get('countrySearchControl')?.hasError('invalidCountrySelection')">
                  {{ 'SIGNUP.VALIDATION.BAD_COUNTRY' | translate }}
                </ng-container>
              </mat-error>
              <mat-autocomplete #countryAuto="matAutocomplete" (optionSelected)="countrySelected($event.option.value)">
                <mat-option *ngFor="let option of countryOptions" [value]="option.label" class="country__option">
                  <div class="country__option__text">{{ option.label }}</div>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </EXP__glxy-wrap>
        </ng-container>
        <ng-template #countriesLoading>
          <uikit-list-stencil
            [showHeader]="false"
            [numRows]="1"
            [rowHeight]="'50px'"
            [sidePadding]="'0'"
          ></uikit-list-stencil>
        </ng-template>
        <glxy-phone-input
          *ngIf="formFieldEnabled.phoneNumber | async"
          #phoneNumberComponent
          required="true"
          [formControl]="form.get('phoneNumber')"
          data-testid="phoneNumber-input"
          id="phoneNumber"
        ></glxy-phone-input>

        <div class="sign-up-form-actions">
          <button
            data-action="step-two-submit"
            class="sign-up-form-cta full-width"
            *ngIf="!loading; else buttonWithSpinner"
            mat-flat-button
            color="primary"
            type="button"
            (click)="onSubmitStepTwo()"
            data-testid="signup-button"
          >
            <ng-container>
              {{ 'SIGNUP.BUTTONS.NEXT' | translate }}
            </ng-container>
          </button>
          <ng-template #buttonWithSpinner>
            <button mat-flat-button color="primary" class="sign-up-form-cta full-width">
              <span>
                <mat-progress-spinner mode="indeterminate" diameter="26"></mat-progress-spinner>
              </span>
            </button>
          </ng-template>
        </div>

        <div class="sign-up-form-privacy-policy">
          {{ 'SIGNUP.PRIVACY_POLICY_PRELINK' | translate }}
          <a data-action="step-two-privacy" href="https://www.vendasta.com/privacy-policy" target="_blank">
            {{ 'SIGNUP.PRIVACY_POLICY_LINK' | translate }}
          </a>
          {{ 'SIGNUP.PRIVACY_POLICY_POSTLINK' | translate }}
          <span [innerHTML]="'SIGNUP.RECAPTCHA_BRANDING' | translate"></span>
        </div>
      </div>
    </div>
    <ng-template #loadingForm>
      <div class="loading-container">
        <mat-progress-bar class="sign-up-progress" mode="indeterminate"></mat-progress-bar>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #afterSignUpWaiting>
  <div class="sign-up-form-explanation sign-up-form-swiper-container">
    <div class="sign-up-form-explanation-title">
      {{ 'SIGNUP.SETUP.AFTER_SIGNUP_WAITING.TITLE' | translate }}
    </div>
    <div class="sign-up-form-explanation-title">
      {{ 'SIGNUP.SETUP.AFTER_SIGNUP_WAITING.TITLE_MORE' | translate }}
    </div>
    <div class="sign-up-form-explanation-subtitle">
      {{ 'SIGNUP.SETUP.AFTER_SIGNUP_WAITING.SUBTITLE' | translate }}
    </div>
    <mat-progress-bar class="sign-up-progress" mode="indeterminate"></mat-progress-bar>
    <img src="../../assets/images/your-logo-here.png" class="your-logo-here" alt="Your Logo Here" />
  </div>
</ng-template>

<ng-template #verifyEmailStep>
  <div class="email-verification-form-explanation sign-up-form-swiper-container">
    <div class="email-verification-form-explanation-title">
      {{ 'SIGNUP.SETUP.VERIFY_EMAIL_STEP.TITLE' | translate }}
    </div>
    <p
      class="email-verification-form-explanation-subtitle"
      [innerHTML]="'SIGNUP.SETUP.VERIFY_EMAIL_STEP.SUBTITLE' | translate: { email: emailSentTo }"
    ></p>
    <p class="email-verification-form-explanation-subtitle">
      {{ 'SIGNUP.SETUP.VERIFY_EMAIL_STEP.FOLLOW_THE_LINK' | translate }}
    </p>
    <div class="email-verification-form-actions">
      <button
        data-action="continue-to-platform"
        class="email-verification-form-button"
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="continuingToPlatform"
        (click)="continueToPlatform()"
        data-testid="continue-to-platform"
      >
        <glxy-button-loading-indicator [isLoading]="continuingToPlatform">
          {{ 'SIGNUP.SETUP.VERIFY_EMAIL_STEP.CONTINUE_TO_PLATFORM' | translate }}
        </glxy-button-loading-indicator>
      </button>
      <ng-template #buttonWithSpinner>
        <button mat-flat-button color="primary" class="email-verification-form-button">
          <span>
            <mat-progress-spinner mode="indeterminate" diameter="26"></mat-progress-spinner>
          </span>
        </button>
      </ng-template>
      <button
        data-action="resend-verification-email"
        class="email-verification-form-button"
        mat-stroked-button
        type="button"
        (click)="resendVerificationEmail()"
        [disabled]="resendingVerificationEmail"
        data-testid="resend-verification-email"
      >
        <glxy-button-loading-indicator [isLoading]="resendingVerificationEmail">
          {{ 'SIGNUP.SETUP.VERIFY_EMAIL_STEP.RESEND_BUTTON_TEXT' | translate }}
        </glxy-button-loading-indicator>
      </button>
      <ng-template #buttonWithSpinner>
        <button mat-flat-button color="primary" class="email-verification-form-button">
          <span>
            <mat-progress-spinner mode="indeterminate" diameter="26"></mat-progress-spinner>
          </span>
        </button>
      </ng-template>
    </div>
  </div>
</ng-template>
