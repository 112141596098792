{
  "COMMON": {
    "UNDER_CONSTRUCTION": "This is currently under construction. Please try again later!",
    "RELEASE_TYPES": {
      "ALPHA": "This feature is currently in alpha."
    },
    "ACTION_LABELS": {
      "CANCEL": "Cancel",
      "CONTINUE": "Continue",
      "LEARN_MORE": "Learn more",
      "MORE": "More",
      "WANT_TO_LEARN_MORE": "Want to learn more?",
      "TALKE_A_COURSE": "Take a course!",
      "SHOW_LESS": "Show less",
      "LESS": "Less",
      "NEXT": "Next",
      "BACK": "Back",
      "CLOSE": "Close",
      "REMOVE": "Remove",
      "CREATE": "Create",
      "EDIT": "Edit",
      "UPDATE": "Update",
      "RETRY": "Retry",
      "DONE": "Done",
      "DELETE": "Delete",
      "ADD_NEW": "Add new",
      "COMPOSE_MESSAGE": "Compose message",
      "NONE_LABEL": "None",
      "NO_RESULTS": "No results",
      "SEND": "Send",
      "SUBMIT": "Submit",
      "SELECT_DATE": "Select date",
      "SAVE": "Save",
      "CHANGE": "Change",
      "LOAD_MORE": "Load more",
      "SEARCH": "Search",
      "VIEW_DETAILS": "View details",
      "VIEW_LESS": "View less",
      "FINISH": "Finish",
      "END": "End",
      "START": "Start",
      "PREVIOUS": "Previous",
      "DISMISS": "Dismiss"
    },
    "DATES": {
      "FROM_LABEL": "From",
      "TO_LABEL": "To",
      "NEVER": "Never",
      "CUSTOM": "Custom",
      "DATE_RANGE": "Date range"
    },
    "USER": "User",
    "USERS": "Users",
    "GROUP": "Group",
    "GROUPS": "Groups",
    "TABLE": {
      "OF": "of",
      "OF_MANY": "of many",
      "NO_RESULTS": "No results found for filters",
      "TABLE_SHOWING": "Showing {{page}} of {{total}} results",
      "TABLE_SHOWING_OVER_RESULT": "Showing {{page}} of over {{total}} results",
      "TABLE_SHOWING_ALL_RESULT": "Showing all {{total}} results",
      "SORT": {
        "SELECT_COLUMN_PLACEHOLDER": "Add another column to sort by",
        "SELECT_COLUMN_PLACEHOLDER_EMPTY_STATE": "Choose a column to sort by",
        "SELECT_COLUMN_NONE": "None",
        "SORT_COLUMN_ORDER_DESCRIPTION_TEXT": {
          "SORT_BY": "Sort by",
          "THEN_SORT_BY": "then by"
        },
        "SORT_TOGGLE_LABELS": {
          "A_TO_Z": "A to Z",
          "Z_TO_A": "Z to A",
          "_1_TO_9": "1 to 9",
          "_9_TO_1": "9 to 1",
          "FIRST_TO_LAST": "First to last",
          "LAST_TO_FIRST": "Last to first",
          "EARLIEST_TO_LATEST": "Oldest first",
          "LATEST_TO_EARLIEST": "Newest first"
        }
      },
      "EXPORT": {
        "BUTTON": "Export",
        "BUTTON_TOOLTIP_BEFORE_COUNT": "Export",
        "BUTTON_TOOLTIP_AFTER_COUNT": "items"
      },
      "COLUMN_SELECTOR": {
        "BUTTON": "Columns"
      }
    },
    "TYPES": {
      "SUBGROUP": "Sub-group",
      "ACCOUNT": "Account"
    },
    "TERMS": {
      "BUSINESS_CATEGORY": "Business category",
      "COUNTRY": "Country",
      "FEATURES": "Features",
      "FOOTER": "By clicking \"{{button_text}}\", you confirm you intend to interact with Vendasta, and you have read, understood, and agree to our <a target=\"_blank\" href=\"{{terms_of_service_url}}\">Terms of Service</a> and <a target=\"_blank\" href=\"{{privacy_policy_url}}\">Privacy Policy</a> and the use of cookies."
    },
    "SALES_TEAM_LABEL": "Sales team",
    "SALESPERSON_LABEL": "Salesperson",
    "SHOWING": "Showing",
    "MORE_LABEL": "More",
    "LESS_LABEL": "Less",
    "READ_LABEL": "Read",
    "FILTERS": "Filters",
    "FIRST_NAME": "First name",
    "NAME": "Name",
    "LAST_NAME": "Last name",
    "EMAIL_ADDRESS": "Email address",
    "ENTER_VALID_EMAIL": "Please enter a valid email address",
    "ACTIONS": "Actions",
    "CREATED": "Created",
    "NEVER": "Never",
    "FIELD_REQUIRED": "{{ fieldName }} is required",
    "THIS_FIELD_REQUIRED": "This field is required",
    "BUSINESS_APP": "Business App",
    "LOADING": "Loading",
    "MARKET": "Market",
    "SETTINGS": "Settings",
    "ERROR_TRY_AGAIN": "An error occurred. Please try again.",
    "RECAPTCHA_ERROR_TRY_AGAIN": "reCAPTCHA failed, please try again.",
    "CONNECTED": "Connected",
    "NOT_CONNECTED": "Not connected",
    "COPY": "Copy",
    "COPIED_TO_CLIPBOARD": "Value copied to clipboard",
    "POWERED_BY": "Powered by"
  },
  "SIGNUP": {
    "SETUP": {
      "STEP_ONE": {
        "TITLE": "Create a free account",
        "SUBTITLE": "No credit card needed. You can explore the platform on your own or book a demo."
      },
      "STEP_ONE_TRIAL": {
        "TRIAL_TITLE": "Try out Vendasta today!",
        "SUBTITLE": "Access the full Vendasta platform with all features."
      },
      "STEP_ONE_HACKERONE": {
        "TITLE": "Create a free account",
        "SUBTITLE": "Please use your HackerOne email address (@wearehackerone.com) to sign up."
      },
      "STEP_TWO": {
        "TITLE": "Create a free account",
        "SUBTITLE": "No credit card needed"
      },
      "STEP_TWO_TRIAL": {
        "TRIAL_TITLE": "Try out Vendasta today!",
        "SUBTITLE": "Access the full Vendasta platform with all features."
      },
      "STEP_THREE": {
        "TITLE": "Choose your Vendasta free trial",
        "SUBTITLE_1": "Cancel anytime within 14 days.",
        "SUBTITLE_2": "You will still have access to your free subscription with reduced features."
      },
      "VERIFY_EMAIL_STEP": {
        "RESEND_BUTTON_TEXT": "Resend verification email",
        "CONTINUE_TO_PLATFORM": "Continue",
        "SUBTITLE": "A verification email has been sent to <b>{{email}}</b>.",
        "FOLLOW_THE_LINK": "Follow the link in the email to verify your email address and finish signing up.",
        "TITLE": "Verify your email address",
        "RESENT_VERIFICATION_EMAIL": "Email sent"
      },
      "AFTER_SIGNUP_WAITING": {
        "TITLE": "Just a moment.",
        "TITLE_MORE": "We're setting up your brand new account.",
        "SUBTITLE": "It may take up to 30 seconds."
      },
      "SIGNIN_WAITING": {
        "TITLE": "Hang tight.",
        "TITLE_MORE": "You already have an account, let's sign you in.",
        "SUBTITLE": "We are almost there."
      },
      "CREDIT_CARD": {
        "TITLE": "You won't be billed today",
        "SUBTITLE": "Set up your payment. Your card will be automatically charged at the end of your trial period.",
        "ZIP": "Zip/Postal code",
        "START_TRIAL_BUTTON": "Start 14-day free trial",
        "ERROR_MESSAGE": "Could not validate your credit card"
      }
    },
    "VALIDATION": {
      "BAD_COUNTRY": "Please select a country from the list.",
      "PASSWORD_MIN_LENGTH": "Must be 8 or more characters",
      "NO_VENDASTA_EMAIL": "Cannot sign up with an email address managed by Vendasta.",
      "VALID_EMAIL": "Please enter a valid email address",
      "EMAIL_IN_USE": "This email is already in use. To access Partner Center, contact your Vendasta administrator.",
      "EMAIL_IN_USE_PARTNER": "This email is already in use. To access your account, <a href=\"{{loginURL}}\">Log in</a>",
      "EMAIL_EXISTS": "A user with this email address already exists.",
      "DISPOSABLE_EMAIL": "Please enter a valid email address",
      "CREDIT_CARD_ERROR": "Error validating your credit card. Please try again",
      "EMAIL_UNVERIFIED": "You must verify your email address before you can proceed",
      "UNKNOWN_ERROR": "Something went wrong. Please refresh and try again.",
      "NON_HACKERONE_EMAIL": "Email must use the \"@wearehackerone.com\" domain"
    },
    "BUTTONS": {
      "NEXT": "Next",
      "SIGNUP": "Sign up",
      "GOOGLE": "Sign up with Google",
      "MICROSOFT": "Sign up with Microsoft",
      "LINKEDIN": "Sign up with LinkedIn"
    },
    "COMPANY_NAME": "Company name",
    "WEBSITE_URL": "Website URL",
    "NUMBER_OF_EMPLOYEES": "Number of employees",
    "EMAIL": "Work email",
    "FIRST_NAME": "First name",
    "LAST_NAME": "Last name",
    "PASSWORD": "Password",
    "COUNTRY": "Country",
    "PRIVACY_POLICY_PRELINK": "We are committed to protecting your privacy and will not sell your information to any third parties. Please view our ",
    "PRIVACY_POLICY_LINK": "Privacy Policy",
    "PRIVACY_POLICY_POSTLINK": " for more information.",
    "TOS_TOS_PRELINK": "By clicking \"Next\", you confirm you intend to interact with Vendasta, and you have read, understood, and agree to our ",
    "TOS_TOS_LINK": "Terms of Service",
    "TOS_BETWEEN_LINKS": " and ",
    "TOS_PRIVACY_POLICY_LINK": "Privacy Policy",
    "TOS_COOKIE_POLICY_LINK": "Cookie Policy",
    "RECAPTCHA_BRANDING": "This site is protected by reCAPTCHA and the Google <a target=\"_blank\" href=\"https://policies.google.com/privacy\">Privacy Policy</a> and <a target=\"_blank\" href=\"https://policies.google.com/terms\">Terms of Service</a> apply.",
    "OR": "OR",
    "ALREADY_HAVE_AN_ACCOUNT": "Already have an account? ",
    "SIGN_IN": "Log in",
    "BANNER": {
      "STEP_ONE": {
        "QUOTATION": "Without Vendasta, I would never have been able to serve as many clients as I do today. Our bottom line loves Vendasta."
      },
      "STEP_TWO": {
        "QUOTATION": "Over the past four years we've seen our active client base grow 80% YoY. That would not have been possible without Vendasta."
      },
      "STEP_FOUR": {
        "QUOTATION": "For years I’ve felt like the CEO on my own little island. I was always looking for like-minded individuals who could learn from each other. That’s what this community has done for me."
      },
      "VERIFY_EMAIL_STEP": {
        "CHECK_YOUR_INBOX": "Check your inbox for an email like this"
      },
      "TRUSTED_BY": "Trusted by ",
      "CHANNEL_PARTNERS": " channel partners"
    }
  },
  "TIER_SELECTION": {
    "SELECTION_ERROR": "An error occurred.",
    "SUBSCRIPTION_TIERS": {
      "vbp2_startup_subscription": {
        "NAME": "Startup",
        "DESCRIPTION": "For <b>entrepreneurs</b> starting out"
      },
      "vbp2_growth_subscription": {
        "NAME": "Growth",
        "DESCRIPTION": "For teams <b>smaller than 10</b>"
      },
      "vbp2_scale_subscription": {
        "NAME": "Scale",
        "DESCRIPTION": "For teams <b>of 10+</b>"
      },
      "m3_solo_subscription": {
        "DESCRIPTION": "Start your business with payment processing and sales tools.",
        "NAME": "Individual",
        "INTERNAL_NAME": "Individual"
      },
      "m3_small_subscription": {
        "DESCRIPTION": "Grow your brand with a white-label solution for your clients.",
        "NAME": "Team",
        "INTERNAL_NAME": "Team"
      },
      "m3_medium_subscription": {
        "DESCRIPTION": "A powerful end-to-end commerce with the tools your team needs.",
        "NAME": "Advance",
        "INTERNAL_NAME": "Advance"
      },
      "m3_large_subscription": {
        "NAME": "Custom",
        "INTERNAL_NAME": "Custom"
      },
      "vbp_2022_essentials_subscription": {
        "DESCRIPTION": "Start your business with integrated payment processing, sales intelligence tools, and weekly training workshops.",
        "NAME": "Essentials",
        "INTERNAL_NAME": "Essentials"
      },
      "vbp_2022_professional_subscription": {
        "DESCRIPTION": "Build your brand with white-label software provided to your clients. Scale your team's ability to market, sell, bill and fulfill.",
        "NAME": "Professional",
        "INTERNAL_NAME": "Professional"
      },
      "vbp_2022_premium_subscription": {
        "DESCRIPTION": "Powerful features for your clients with multiple locations. An end-to-end commerce platform with the tools your team needs to work efficiently.",
        "NAME": "Premium",
        "INTERNAL_NAME": "Premium"
      },
      "vbp_2022_custom_subscription": {
        "DESCRIPTION": "",
        "NAME": "Custom Plan",
        "INTERNAL_NAME": "Custom Plan"
      }
    },
    "FEATURES": {
      "TEAM_MEMBER_SEAT": "Team Member Seat",
      "TEAM_MEMBER_SEATS": "Team Member Seats"
    },
    "FREE_FOR_14_DAYS": "Free for 14 days",
    "GET_STARTED": "Get started",
    "PRICE_DISCLAIMER": "No onboarding included during trial period. <b>{{price}}</b> billed afterwards.",
    "PER_MONTH": "${{perMonthPrice}}/month",
    "CONTACT_CARD_TITLE": "Not ready to get started?",
    "CONTACT_CARD_DESCRIPTION": "Speak with an expert and learn what plan is right for you.",
    "BOOK_A_MEETING": "Book a 30-minute meeting"
  },
  "BOOK_A_DEMO_SIGNUP": {
    "SETUP": {
      "STEP_ONE": {
        "TITLE": "Book a demo",
        "SUBTITLE": "We'll also create your free account now, no credit card required, so you can explore the platform on your own"
      }
    }
  },
  "CONQUER_SIGNUP": {
    "SETUP": {
      "STEP_ONE": {
        "TITLE": "Get free access to Conquer Local",
        "SUBTITLE": "Sign up or sign in below"
      }
    },
    "BANNER": {
      "STEP_ONE": {
        "QUOTATION": "For years I’ve felt like the CEO on my own little island. I was always looking for like-minded individuals who could learn from each other. That’s what this community has done for me"
      }
    }
  }
}
